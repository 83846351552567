import dayjs from "dayjs";

import { filterByDateRange } from "@aclymatepackages/date-helpers";

import { useEmployeeStatuses } from "../components/employees";
import useEmissionsContext from "../contexts/emissions";
import { useAccountData } from "../firebase";
import useAccountingData from "../hooks/accountingData";

export const useFindAccountingBlockingEmissions = () => {
  const { allEmissions } = useEmissionsContext();
  const [{ startDate, employeeCount }] = useAccountData();
  const [{ mostRecentAccountingDate }] = useAccountingData();
  const [employees] = useEmployeeStatuses();

  return (accountingDate) => {
    if (!accountingDate) {
      return {};
    }

    const emissionsBeforeAccountingDate = allEmissions.filter(({ date }) =>
      filterByDateRange({
        filterStartDate: mostRecentAccountingDate || startDate,
        filterEndDate: accountingDate,
        date,
      })
    );

    const defaultUtilityEmissions = emissionsBeforeAccountingDate.filter(
      ({ source }) => source === "default-utilities"
    );

    const findAreEmployeesBlocking = () => {
      const affectedEmployees = employees.filter(
        ({ startDate, status }) =>
          status !== "terminated" &&
          dayjs(startDate).isBefore(dayjs(accountingDate))
      );

      const unconfirmedEmployees = affectedEmployees.filter(
        ({ status }) => status === "unconfirmed"
      );

      if (!employeeCount) {
        return !!unconfirmedEmployees.length;
      }

      const incompleteEmployees = affectedEmployees.filter(
        ({ status }) => status === "incomplete"
      );
      const remainingEmployees = employeeCount - employees.length;

      return (
        !!unconfirmedEmployees.length ||
        !!incompleteEmployees.length ||
        !!remainingEmployees
      );
    };

    const areEmployeesBlocking = findAreEmployeesBlocking();

    return {
      isBlocked: areEmployeesBlocking || !!defaultUtilityEmissions.length,
    };
  };
};
